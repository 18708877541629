import { useEffect } from 'react';

const POLLING_INTERVAL = 50; // 50ms

export const usePolling = ({
  startPolling,
  stopPolling,
  poll = true,
}: {
  startPolling(_: number): void;
  stopPolling(): void;
  poll?: boolean;
}) => {
  useEffect(() => {
    if (!poll) return;

    startPolling(POLLING_INTERVAL);

    return () => stopPolling();
  }, [startPolling, stopPolling, poll]);
};
