import React, { useEffect } from 'react';

import styled from '@emotion/styled';

import { COLORS, ExternalContent, SkeletonLoader } from '@clutter/clean';
import {
  CompletionStatus,
  useCompleteMutation,
  useCompletionQuery,
} from '@graphql/landing';
import { usePolling } from '@utils/hooks/use_polling';

const Container = styled.div`
  background: ${COLORS.dust};
  padding: 12px 32px;
  margin: 32px 0;
  border-radius: 8px;
`;

const Fetcher: React.FC<{
  sid: string;
}> = ({ sid }) => {
  const { startPolling, stopPolling, data } = useCompletionQuery({
    variables: { sid },
  });
  const status = data?.completion?.status;
  const html = data?.completion?.html;

  usePolling({
    startPolling,
    stopPolling,
    poll: status !== CompletionStatus.Processed,
  });

  return (
    <Container>
      {html ? (
        <ExternalContent dangerouslySetInnerHTML={{ __html: html }} />
      ) : (
        <SkeletonLoader />
      )}
    </Container>
  );
};

export const Complete: React.FC<{
  text: string;
}> = ({ text }) => {
  const [execute, { data }] = useCompleteMutation();
  const sid = data?.complete?.completion?.sid;

  useEffect(() => {
    execute({
      variables: { text },
    });
  }, [text]);

  if (!sid) return null;
  return <Fetcher sid={sid} />;
};
